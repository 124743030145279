import Vuex from 'vuex';
import { createLocalVue, shallowMount } from '@vue/test-utils';

import { nameSpace as UserDataNameSpace } from '@/vue/stores/UserData/UserDataStore';
import { nameSpace as ProfileNameSpace } from '@Profile/store';
import { nameSpace as CSOperatorNameSpace } from '@/vue/stores/CSOperator/CSOperatorStore';

import { nameSpace as PaymentMethodsHubNameSpace } from './stores/PaymentMethodsHub';

import { ACTIONS } from './constants/paymentMethodsHub';

import PaymentMethodsHub from './PaymentMethodsHub';

const mockAddCompanyNewPaymentMethod = jest.fn();
const mockAddUserNewPaymentMethod = jest.fn();
const mockGetCompanyPaymentMethodsCollection = jest.fn();
const mockGetUserPaymentMethodsCollection = jest.fn();
const mockSetCompanyDefaultPaymentMethod = jest.fn();
const mockSetUserDefaultPaymentMethod = jest.fn();
const mockRemoveCompanyPaymentMethod = jest.fn();
const mockRemoveUserPaymentMethod = jest.fn();

jest.mock('./paymentMethodsProviders/PaymentProviderComponents.js', () => ({
  providerUtils: jest.fn().mockResolvedValue({}),
  providerAPI: (() => ({
    addCompanyNewPaymentMethod: mockAddCompanyNewPaymentMethod,
    addUserNewPaymentMethod: mockAddUserNewPaymentMethod,
    getCompanyPaymentMethodsCollection: mockGetCompanyPaymentMethodsCollection,
    getUserPaymentMethodsCollection: mockGetUserPaymentMethodsCollection,
    setCompanyDefaultPaymentMethod: mockSetCompanyDefaultPaymentMethod,
    setUserDefaultPaymentMethod: mockSetUserDefaultPaymentMethod,
    removeCompanyPaymentMethod: mockRemoveCompanyPaymentMethod,
    removeUserPaymentMethod: mockRemoveUserPaymentMethod,
  })),
}));

jest.mock('./paymentMethodsProviders/PaymentProviderComponents', () => ({
  providerUtils: () => jest.fn(),
  providerAPI: () => jest.fn(),
}));

jest.mock('vuex-composition-helpers/dist', () => ({
  useStore: () => ({
    getters: {
      'UserData/getUserData': {
        company: {
          uuid: 'test',
        },
        roles: ['default'],
      },
      'CSOperator/getCurrentCSOperator': {},
      'Profile/getCurrentProfile': {},
      'Profile/getProfileCollection': [],
    },
  }),
}));

const paymentMethodsStore = {
  namespaced: true,
  getters: {
    getDefaultPaymentMethodUUID: () => jest.fn().mockResolvedValue('A'),
    getPaymentMethodByUUID: () => jest.fn().mockResolvedValue({
      holder: 'B',
    }),
  },
  actions: {
    updateSource: () => jest.fn(),
  },
  state: {
    source: 'A',
  },
};

const mockedModules = {
  [UserDataNameSpace]: { namespaced: true },
  [ProfileNameSpace]: { namespaced: true },
  [CSOperatorNameSpace]: { namespaced: true },
  [PaymentMethodsHubNameSpace]: paymentMethodsStore,
};

const localVue = createLocalVue();
localVue.use(Vuex);

const store = new Vuex.Store({
  modules: mockedModules,
});

let wrapper;

const mocks = {
  $route: {},
};

const commonCollection = [
  {
    name: 'payment-1',
    userProfile: {
      uuid: '1',
    },
  },
  {
    name: 'payment-2',
    userProfile: {
      uuid: null,
    },
  },
];

describe('Given PaymentMethodsHub', () => {
  beforeEach(() => {
    wrapper = shallowMount(PaymentMethodsHub, {
      data() {
        return {
          candidatePaymentMethod: 'candidate-payment-method',
          flowTracking: {
            currentAction: ACTIONS.add,
            addIsEmbedded: false,
            previousAction: null,
          },
        };
      },
      localVue,
      store,
      mocks,
    });
    wrapper.vm.getPaymentMethodsCollection = jest.fn().mockResolvedValue(commonCollection);
    wrapper.vm.savePaymentMethodsCollection = jest.fn().mockResolvedValue();
  });

  afterEach(() => {
    jest.clearAllMocks();
    jest.resetModules();
  });

  describe('When the "onAddPaymentMethodSubmit" method is called', () => {
    describe('And the candidatePaymentMethod data is "candidate-payment-method"', () => {
      it('Then the "addNewPaymentMethod" is called with "candidate-payment-method"', async () => {
        wrapper.vm.addNewPaymentMethod = jest.fn().mockResolvedValue({});

        const addNewPaymentMethodSpy = jest.spyOn(wrapper.vm, 'addNewPaymentMethod');

        await wrapper.vm.onAddPaymentMethodSubmit();

        expect(addNewPaymentMethodSpy).toHaveBeenCalledWith('candidate-payment-method', {
          addIsEmbedded: false,
          currentAction: 'add',
          isEmbedded: false,
          previousAction: null,
          stage: 'SUBMITTING',
        }, 'Adyen/3.23.0');
      });

      it('Then the "onResponse" is called with "response"', async () => {
        wrapper.vm.addNewPaymentMethod = jest.fn().mockResolvedValue('response');

        const onResponseSpy = jest.spyOn(wrapper.vm, 'onResponse');

        await wrapper.vm.onAddPaymentMethodSubmit();

        expect(onResponseSpy).toHaveBeenCalledWith('response');
      });
    });
  });

  describe('When the "refreshPaymentMethodsCollection" method is called', () => {
    it('Then the "getPaymentMethodsCollection" method is called', async () => {
      const getPaymentMethodsCollectionSpy = jest.spyOn(wrapper.vm, 'getPaymentMethodsCollection');

      await wrapper.vm.refreshPaymentMethodsCollection();

      expect(getPaymentMethodsCollectionSpy).toHaveBeenCalled();
    });

    it('Then the "savePaymentMethodsCollection" method is called', async () => {
      const savePaymentMethodsCollectionSpy = jest.spyOn(wrapper.vm, 'savePaymentMethodsCollection');

      await wrapper.vm.refreshPaymentMethodsCollection();

      expect(savePaymentMethodsCollectionSpy).toHaveBeenCalled();
    });
  });

  describe('When the "onAddPaymentMethodClose" is called', () => {
    it('Then the "on:close" event is emitted', async () => {
      await wrapper.vm.onAddPaymentMethodClose();

      expect(wrapper.emitted('on:close')).toBeTruthy();
    });
  });

  describe('When the "onPaymentMethodSelected" is called', () => {
    describe('And selectInBackground is false', () => {
      it('Then the "setDefaultPaymentMethod" is called', async () => {
        wrapper = shallowMount(PaymentMethodsHub, {
          data() {
            return {
              candidatePaymentMethod: 'candidate-payment-method',
              flowTracking: {
                currentAction: ACTIONS.add,
                addIsEmbedded: false,
                previousAction: null,
                isEmbedded: false,
              },
            };
          },
          propsData: {
            selectInBackground: false,
          },
          localVue,
          store,
          mocks,
        });

        wrapper.vm.getPaymentMethodByUUID = jest.fn().mockResolvedValue(commonCollection[0]);

        const setDefaultPaymentMethodSpy = jest.spyOn(wrapper.vm, 'setDefaultPaymentMethod').mockResolvedValue({});

        await wrapper.vm.onPaymentMethodSelected('uuid', true);

        expect(setDefaultPaymentMethodSpy).toHaveBeenCalled();
      });
    });

    describe('And selectInBackground is true', () => {
      it('Then the "setDefaultPaymentMethod" is not called but "paymentMethodEmitCollector" is called', async () => {
        wrapper = shallowMount(PaymentMethodsHub, {
          data() {
            return {
              candidatePaymentMethod: 'candidate-payment-method',
              flowTracking: {
                currentAction: ACTIONS.add,
                addIsEmbedded: false,
                previousAction: null,
                isEmbedded: false,
              },
            };
          },
          propsData: {
            selectInBackground: true,
          },
          localVue,
          store,
          mocks,
        });

        wrapper.vm.getPaymentMethodByUUID = jest.fn().mockResolvedValue(commonCollection[0]);

        const setDefaultPaymentMethodSpy = jest.spyOn(wrapper.vm, 'setDefaultPaymentMethod').mockResolvedValue({});
        const paymentMethodEmitCollectorSpy = jest.spyOn(wrapper.vm, 'paymentMethodEmitCollector');

        await wrapper.vm.onPaymentMethodSelected('uuid', true);

        expect(setDefaultPaymentMethodSpy).not.toHaveBeenCalled();
        expect(paymentMethodEmitCollectorSpy).toHaveBeenCalled();
      });
    });
  });

  describe('When the "launchAddPaymentMethod" is called from the select subcomponent', () => {
    it('Then the isAddPaymentMethodModalOpen becomes true and flowTracking changes accordingly', async () => {
      await wrapper.vm.launchAddPaymentMethod('select');

      expect(wrapper.vm.isAddPaymentMethodModalOpen).toBe(true);
      expect(wrapper.vm.flowTracking).toStrictEqual({
        currentAction: ACTIONS.add,
        addIsEmbedded: false,
        previousAction: 'select',
        isEmbedded: false,
        stage: null,
      });
    });
  });

  describe('When a response is triggered to "handleExternalResponse"', () => {
    it('Then the "onResponse" is called', async () => {
      const onResponseSpy = jest.spyOn(wrapper.vm, 'onResponse');

      wrapper.vm.handleExternalResponse = 'test';

      await wrapper.vm.$nextTick();
      expect(onResponseSpy).toHaveBeenCalled();
    });
  });

  describe('When "deletePaymentMethod" is called', () => {
    it('Then the removePaymentMethod is called', async () => {
      const removePaymentMethodSpy = jest.spyOn(wrapper.vm, 'removePaymentMethod');
      const refreshPaymentMethodsCollectionSpy = jest.spyOn(wrapper.vm, 'refreshPaymentMethodsCollection');

      await wrapper.vm.deletePaymentMethod('uuid');

      expect(removePaymentMethodSpy).toHaveBeenCalledWith('uuid', 'Adyen/3.23.0');
      expect(refreshPaymentMethodsCollectionSpy).toHaveBeenCalled();
    });
  });

  describe('When "updateDefaultPaymentMethod" is called', () => {
    it('Then the setDefaultPaymentMethod is called', async () => {
      const setDefaultPaymentMethodSpy = jest.spyOn(wrapper.vm, 'setDefaultPaymentMethod');
      const refreshPaymentMethodsCollectionSpy = jest.spyOn(wrapper.vm, 'refreshPaymentMethodsCollection');

      await wrapper.vm.updateDefaultPaymentMethod('uuid');

      expect(setDefaultPaymentMethodSpy).toHaveBeenCalledWith('uuid', 'Adyen/3.23.0');
      expect(refreshPaymentMethodsCollectionSpy).toHaveBeenCalled();
    });
  });

  describe('When the "source" is changed', () => {
    it('Then the "onResponse" is called', async () => {
      const refreshPaymentMethodsCollectionSpy = jest.spyOn(wrapper.vm, 'refreshPaymentMethodsCollection');

      paymentMethodsStore.state.source = 'B';

      wrapper.vm.source = 'test';

      await wrapper.vm.$nextTick();
      expect(refreshPaymentMethodsCollectionSpy).toHaveBeenCalled();
    });
  });
});
